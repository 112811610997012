import React from 'react'
import { useSpring, animated } from 'react-spring'
import './progressBar.css'

const ProgressBar = ({ totalSlides, active, setActiveSlide, activeSlide, slideIndex, reversed, setReversed }) => {

    // Logic for progressing through the animation or reversing it
    const resting = () => {
        if (!reversed) {
            if (activeSlide === totalSlides - 1) {
                setReversed(true)
            } else {
                setActiveSlide(activeSlide + 1)
            }
        } else {
            if (activeSlide === 0) {
                setReversed(false)
            } else {
                setActiveSlide(activeSlide - 1)
            }
        }
    }

    // Values to set the animations to depending on the conditions
    const conditions = () => {
        if (reversed) {
            if (active) {
                return '0%'
            } else {
                if (slideIndex > activeSlide) {
                    return '0%'
                } else {
                    return '100%'
                }
            }
        } else {
            if (active) {
                return '100%'
            } else {
                if (slideIndex < activeSlide) {
                    return '100%'
                } else {
                    return '0%'
                }
            }
        }
    }

    // Animation using useSpring hook from React Spring
    const spring = useSpring({
        from: {
            width: '0%'
        },
        width: conditions(),
        config: {
            duration: reversed ? 1000 : 6000,
        },
        onRest: active && resting,
        delay: reversed ? 0 : activeSlide !== 0 ? 500 : 0,
    })

    return (
        <animated.div
            className="progress"
            style={spring} />
    )
}

export default ProgressBar