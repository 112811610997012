import React, { useRef } from "react"
import "./card.css"
import { Link } from "gatsby"
import ProductImage from "../productImage/productImage"
import { useInView } from "react-intersection-observer"
import { useSpring, animated, useChain } from "react-spring"
import { useWindowSize } from "../windowSize/windowSize"

const Card = props => {
  // Initialising the useInView hook
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })

  // intitialising all of the refs of the individual components
  const item1Ref = useRef()
  const item2Ref = useRef()
  const item3Ref = useRef()
  const item4Ref = useRef()

  // Holding all of the individual delays inside a variable
  const delays = {
    h1: 100,
    p: 300,
    link: 500,
    img: 700,
  }

  // Link the items to the animations using the custom hook useAnimation
  const item1Style = useAnimation(item1Ref, delays.h1, inView, true)
  const item2Style = useAnimation(item2Ref, delays.p, inView, true)
  const item3Style = useAnimation(item3Ref, delays.link, inView, true)
  const item4Style = useImgAnimation(
    item4Ref,
    delays.img,
    inView,
    {
      opacity: inView ? 1 : 0,
      transform: `scale(${inView ? 1 : 0.8})`,
    } /* false, ['scale'] */
  )

  // Entry and exit animation for the cards
  const styles = {
    opacity: inView ? 1 : 0,
    transform: `
            scale(${inView ? 1 : 0.7})
            `,
    transition: "all 0.4s ease-out 0.05s",
  }

  // Adding the individual components animations into an animation spring using useChain
  useChain([item1Ref, item2Ref, item3Ref, item4Ref])

  // Setting the window width as a local variable
  const [clientWidth] = useWindowSize()

  return (
    // Product card
    <div className="card" ref={ref} style={styles}>
      {/* translucent background */}
      <div className="card-background" />

      {/* Align text to left on large screen */}
      <div className="large-left">
        <animated.h1
          className="card-title"
          style={
            (item1Style,
            {
              color: "#E1873D",
            })
          }
        >
          {props.title}
        </animated.h1>
        <animated.p className="card-body" style={item2Style}>
          {props.body}
        </animated.p>
        <animated.div style={item3Style}>
          <div
            className="large-align-left"
            style={{ textAlign: clientWidth < 1024 ? "center" : "left" }}
          >
            <Link to={props.link_url}>{props.link_text}</Link>
          </div>{" "}
        </animated.div>
      </div>

      {/* Align to the right on large screen */}
      <div className="image-wrapper">
        <animated.div className="image-container" style={item4Style}>
          {props.slide && (
            <ProductImage
              uk={
                props.slide.image_uk
                  ? props.slide.image_uk.asset !== null && props.slide.image_uk
                  : 0
              }
              restOfWorld={props.slide.image}
              alt={props.slide.product.title}
              style={{ height: "100%", zIndex: 3 }}
              imgStyle={{
                objectFit: "contain",
                objectPosition: "bottom center",
              }}
              objectFit="contain"
            />
          )}
          {/* <div className="background"></div> */}
        </animated.div>
      </div>
    </div>
  )
}

export default Card

// useAnimation hook to control the refs and animations
const useAnimation = (ref, delay, inView, transform, springs) => {
  // Animation properties using react spring hook useSpring
  const spring = useSpring({
    ref: ref,
    delay: inView ? (delay ? delay : 0) : 0,
    opacity: inView ? 1 : 0,
    transform: ` translateX(-${transform ? (inView ? 0 : 30) : 0}px)`,
    ...(springs && { springs }),
  })

  // return the animation
  return spring
}

// Seperate animation hook for the image animations
const useImgAnimation = (ref, delay, inView, springs) => {
  const spring = useSpring({
    ref: ref,
    ...(inView && { delay: delay ? delay : 0 }),
    ...(springs && { ...springs }),
  })
  return spring
}
