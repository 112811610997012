import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "./allProducts.css"
import LongCard from "../longCard/longCard"
import InView from "../inView/inView"

const AllProducts = () => {
  // Get all of the products from the sanity cms
  const data = useStaticQuery(graphql`
    query {
      allSanityProduct(sort: { fields: index }) {
        edges {
          node {
            title
            slug {
              current
            }
            card_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            card_image_uk {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {/* absolute positioned page title for small screens to work with offset products */}
      <div className="hide-large">
        <span className="relative" id="allProducts">
          <h1 className="section-title">All Products</h1>
        </span>
      </div>

      <section className="all-products">
        {/* show normal page title on large screens */}
        <h1
          className="hide-mobile"
          style={{
            color: "#E1873D",
          }}
        >
          All Products
        </h1>

        {/* sidescrolling conmponent on small screens with offset products */}
        <div className="sidescroller">
          {/* render all of the products from the above query inside a "long card" */}
          {data.allSanityProduct.edges.map(({ node: product }) => (
            <InView>
              <LongCard
                link_text={product.title}
                link_url={product.slug.current}
                image_url={product.card_image.asset.fluid}
                image_alt={product.title}
                product={product}
              />
            </InView>
          ))}
          <div className="padding"></div>
        </div>
      </section>
    </>
  )
}

export default AllProducts
