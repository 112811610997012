import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import './longCard.css';
import ProductImage from '../productImage/productImage'

// Long card component for the allProducts section
const LongCard = props => {
    return (
        // Click anywhere on the card to go to the relevant product
        <Link to={props.link_url}>
            <div className={props.active ? "active-card" : "long-card"}>
                {/* Inner of the long card holds the information */}
                <div className="long-card-inner">
                    {/* Product title / Link */}
                    {!props.notLink && <Link to={props.link_url}>{props.link_text}</Link>}
                    {props.notLink && <h2 className="link-mock">{props.link_text}</h2>}
                    {/* Product image section */}
                    <div className="img-container">
                        {/* If it isn't an image add image using gatsby image */}
                        {!props.product && <Img fluid={props.image_url} alt={props.image_alt} imgStyle={{ objectFit: 'contain', objectPosition: 'left' }} />}
                        {/* If card is a product add image using ProductImage component */}
                        {props.product && <ProductImage
                            uk={props.product.card_image_uk ? props.product.card_image_uk.asset !== null && props.product.card_image_uk : 0}
                            restOfWorld={props.product.card_image}
                            alt={props.product.title} />}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default LongCard