import React from 'react'
import { useInView } from 'react-intersection-observer'

// InView component applies an animation to the children when they enter or leave the viewport 
const InView = ({ children }) => {

    // Extracting variables from useInView hook
    const [ref, inView] = useInView({
        /* Optional options */
        threshold: 0,
    })

    // CSS animation toggling on "inView"
    const styles = {
        opacity: inView ? 1 : 0,
        transform: `
            rotateY(${!inView ? '35deg' : 0})
            scale(${inView ? 1 : 0.9})
            `,
        transition: 'all 0.4s ease-out 0.05s'
    }

    return (
        // Wrap children with animated div
        <div ref={ref} style={styles}>
            {children}
        </div>
    )
}

export default InView