import React, { useContext } from "react"
import Img from "gatsby-image/withIEPolyfill"
import { GlobalStateContext } from "../context/globalContextProvider"

// Component to render product image based on set location
const ProductImage = ({ uk, restOfWorld, alt, style, imgStyle, objectFit }) => {
  // get context of location from the global state
  const state = useContext(GlobalStateContext)

  // Ensure localStorage is defined before setting the location as the value
  let setLocation = ""
  if (typeof localStorage !== "undefined") {
    setLocation =
      localStorage.getItem("location") !== ""
        ? localStorage.getItem("location")
        : state.location
  }

  return (
    // Toggle image depending on the location
    <Img
      fluid={
        setLocation === "Europe"
          ? uk
            ? uk.asset.fluid
            : restOfWorld.asset.fluid
          : restOfWorld.asset.fluid
      }
      alt={alt}
      style={style}
      imgStyle={imgStyle}
      objectFit={objectFit}
    />
  )
}

export default ProductImage
