import React, { useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Card from "../card/card"
import "./featuredSection.css"
import { useSpring, animated } from "react-spring"
import AllProducts from "../allProducts/allProducts"
import ProgressBar from "../progressBar/progressBar"
import { useWindowSize } from "../windowSize/windowSize"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/globalContextProvider"

// Featured section component
const FeaturedSection = ({ setRef }) => {
  // Import global variables to the local scope, i.e. whether allLocations section is true
  const { allProducts } = useContext(GlobalStateContext)

  // Import method to update global variables
  const dispatch = useContext(GlobalDispatchContext)

  // Get slider data from Sanity
  const data = useStaticQuery(graphql`
    query {
      allSanityPromoSlider {
        edges {
          node {
            title
            body
            link_text
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image_uk {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            product {
              title
              card_image {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              slug {
                current
              }
              options_with_values {
                attribute_1_values {
                  values
                }
              }
            }
          }
        }
      }
    }
  `)

  // Get the total number of slides to set as the grid amount
  const totalSlides = data.allSanityPromoSlider.edges.length

  // Make an array with the total number of slides
  const progressBars = [...Array(totalSlides)]

  // holding the active slide and whether the slider is reversed in the state
  const [activeSlide, setActiveSlide] = useState(0)
  const [reversed, setReversed] = useState(false)

  // Spring animation for the slider
  const spring = useSpring({
    // setting the total columns based on the amount of slides
    gridTemplateColumns: `repeat(${totalSlides}, 100%)`,
    // Tranform to the left depending on the active slide
    transform: `translateX(-${activeSlide * 200}%)`,
  })

  // CSS transition for the slider
  const slider = {
    gridTemplateColumns: `repeat(${totalSlides}, 100%)`,
    transform: `translateX(-${activeSlide * 200}%)`,
    transition: "all 0.4s ease-in",
  }

  // Extracting the window width into a local variable
  const [clientWidth] = useWindowSize()

  return (
    <>
      {/* If window width is more than 1024 show the desktop version */}
      {clientWidth >= 1024 ? (
        <section className="featured-section" ref={setRef}>
          {/* If state variable "allProducts" is false show the slideshow */}
          {!allProducts && (
            <animated.div className="featured-section-inner" style={slider}>
              {/* Loop through slides array and return Cards for each */}
              {data.allSanityPromoSlider.edges.map(({ node: slide }) => (
                <Card
                  setRef={setRef}
                  title={slide.title}
                  body={slide.body}
                  link_url={slide.product.slug.current}
                  link_text={slide.link_text}
                  image_url={slide.image.asset.fluid}
                  image_alt={slide.product.title}
                  slide={slide}
                />
              ))}
            </animated.div>
          )}

          {/* If state variable "allProducts" is true show the All Products section */}
          {allProducts && (
            <div
              style={{
                maxHeight: "650px",
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <AllProducts />
            </div>
          )}

          {/* Slideshow controls and all products switch */}
          <div className="home-content-switch" style={{ width: "200px" }}>
            {/* Show slideshow progress is "allProducts" is false */}
            {!allProducts && (
              <div className="progress-wrapper" style={{ width: "100%" }}>
                {/* Create a progress bar for each slide */}
                {progressBars.map((bar, index) => (
                  <div className="progress-bar">
                    <ProgressBar
                      totalSlides={totalSlides}
                      active={activeSlide === index ? true : false}
                      activeSlide={activeSlide}
                      setActiveSlide={setActiveSlide}
                      slideIndex={index}
                      reversed={reversed}
                      setReversed={setReversed}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* All products / Slideshow switch */}
            <button
              onClick={
                () => {
                  dispatch({
                    type: allProducts
                      ? "HIDE_ALL_PRODUCTS"
                      : "SHOW_ALL_PRODUCTS",
                  })
                } /* setShowSlideshow(!showSlideshow) */
              }
              className="view-products-btn"
            >
              {!allProducts ? "View All Products" : "View Featured"}
            </button>
          </div>

          {/* Page title */}
          <h1 className="transparent-title">
            {!allProducts ? "Featured" : "All Products"}
          </h1>
        </section>
      ) : (
        // If client width is less than 1024 return the featured section on its own
        <section className="featured-section" ref={setRef}>
          <animated.div className="featured-section-inner" style={spring}>
            {data.allSanityPromoSlider.edges.map(({ node: slide }) => (
              <Card
                setRef={setRef}
                title={slide.title}
                body={slide.body}
                link_url={slide.product.slug.current}
                link_text={slide.link_text}
                image_url={slide.image.asset.fluid}
                image_alt={slide.product.title}
                slide={slide}
              />
            ))}
          </animated.div>
        </section>
      )}
    </>
  )
}

export default FeaturedSection
