import React from "react"
import Layout from "../components/layout/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import AllProducts from '../components/allProducts/allProducts'
import FeaturedSection from '../components/featuredSection/featuredSection'
import InView from '../components/inView/inView'

const IndexPage = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Home" />
      {/* Placeholder cards before graphQL queries are added */}
      <FeaturedSection />

      {/* All products section */}
      <div className="hide-large">
        <InView>
          <AllProducts />
        </InView>
      </div>
    </Layout>
  )
}

export default IndexPage
